import React, { Component } from 'react';
import { HashRouter, Routes, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import './scss/style.scss';
import './App.css';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

const App = () => {

  const queryClient = new QueryClient()

  return (
    <QueryClientProvider client={queryClient}>
      <HashRouter>
          <React.Suspense fallback={loading}>
          <Routes>
            <Route path="*" name="Home" element={<TheLayout />} />
          </Routes>
        </React.Suspense>
      </HashRouter>
    </QueryClientProvider>        
  );
}

export default App;
