import { sygnet } from './sygnet'
import { logo } from './logo'
import { logoNegative } from './logo-negative'

import {
  cilApplicationsSettings,
  cidSettings,
  cidSpeedometer,
  cilSun,
  cilMoon,
  cidCalendar,
  cisCalendar,
  cisCalendarNote,
  cisCalendarToday,
  cidLockLocked,
  cidLockUnlocked,
  cidUser,
  cidFire,
  cidFax,
  cidFolderShared,
  cidSearch,
  cidStarCircle,
  cisReload,
  cidEye,
  cidMonitor,
  cisMenu,
  cidFolderAlt,
  cisHeart,  
  cidDocument,
  cisExternalLink,
  cidFolderPlus,
  cidFilter,
  cilFilter,
  cisX,
  cidHistory,
  cidBarChart,
  cidChartDonut,
  cidBook,
  cidFile,
  cidFilePdf,
  cidFileVideo,
  cidFileImage,
  cisCircle,
  cisMediaRecord,
  cisMediaPlay,
  cidChatBubble,
  cisEyeSlash,
  cisPlusCircle,
  cisPlusSquare,
  cisMinusCircle,
  cisCommentBubblePlus,
  cidShareAlt,
  cisChevronLeft,
  cisChevronRight,
  cisChevronDoubleLeft,
  cisChevronDoubleRight,
  cisCog,
  cidPenFancy,
  cidLinkIntact,
  cisLinkIntact,
  cisLinkSlash,
  cisTrash,
  cisPen,
  cisLineSpacing,
  cidUserFollow,
  cisWarning,
  cisXCircle,
  cisCheckCircle, 
  cisPrinter,
  cisFileAdd,
  cisCursor,  
  cidHospital,
  cidDataTransferUp,
  cidArchive,
  cidBurn,
  cidSend,
  cidNote,
  cidNotes,  
  cidAccountLogout,
  cidBadge,
  
  cibWindows,  
  cibApple,
  cibAndroidAlt,  
  
  cibGoogleChrome,
  cibMicrosoftEdge,
  cibMozillaFirefox,
  cibSafari,

  cidDeviceUnknown,
  cisPlus,
  cidIdCard,
  cidSync,
  cidBuilding,
  cisBuilding,
  cisBan,
  cisMobileSpeaker,
  cisPerson,
  cisFiberManual,
  cidClock,
  cidFileExcel,
  cidUserFemalePlus,
  cidSchool,
  cidShieldCheck,
  cidAsteriskCircle,  
  cisDollarCircle,
  cisUpdate,  
  cidCloudDownload,
  cisScanner,
  cisCloudUpload,
  cidHeadphonesMic,
  cidGrid,
  cisColumns,
  cisPage,
  cidTransfer,
  cisArrowRight,
  cisCalendarViewDay,
  cidStorage,
  cidShareBoxed,
  cidBullhorn,
  cidHourglass,
  cisFlagAlt,
} from '@coreui/icons-pro'

export const icons = Object.assign({}, {
  sygnet,
  logo,
  logoNegative
}, {
  cilApplicationsSettings,
  cidSettings,
  cidSpeedometer,
  cilSun,
  cilMoon,
  cidCalendar,
  cisCalendar,
  cisCalendarNote,
  cisCalendarToday,
  cidLockLocked,
  cidLockUnlocked,
  cidUser,
  cidFire,
  cidFax,
  cidFolderShared,
  cidSearch,
  cidStarCircle,
  cisReload,
  cidEye,
  cidMonitor,
  cisMenu,
  cidFolderAlt,
  cisHeart,  
  cidDocument,
  cisExternalLink,
  cidFolderPlus,
  cidFilter,
  cilFilter,
  cisX,
  cidHistory,
  cidBarChart,
  cidChartDonut,
  cidBook,
  cidFilePdf,
  cidFileVideo,
  cidFileImage,
  cisCircle,
  cisMediaRecord,
  cisMediaPlay,
  cidChatBubble,
  cisEyeSlash,
  cisPlusCircle,
  cisPlusSquare,
  cisMinusCircle,
  cisCommentBubblePlus,
  cidShareAlt,
  cisChevronLeft,
  cisChevronRight,
  cisChevronDoubleLeft,
  cisChevronDoubleRight,
  cisCog,
  cidPenFancy,
  cidLinkIntact,
  cisLinkIntact,
  cisLinkSlash,
  cisTrash,
  cisPen,
  cisLineSpacing,
  cidUserFollow,
  cisWarning,
  cisXCircle,
  cisCheckCircle,
  cisPrinter,
  cisFileAdd,
  cisCursor,  
  cidHospital,
  cidDataTransferUp,
  cidFile,
  cidArchive,
  cidBurn,
  cidSend,
  cidNote,
  cidNotes,
  cidAccountLogout,
  cidBadge,

  cibWindows,  
  cibApple,
  cibAndroidAlt,  
  
  cibGoogleChrome,
  cibMicrosoftEdge,
  cibMozillaFirefox,
  cibSafari,

  cidDeviceUnknown,
  cisPlus,
  cidIdCard,
  cidSync,
  cidBuilding,
  cisBuilding,
  cisBan,
  cisMobileSpeaker,
  cisPerson,
  cisFiberManual,
  cidClock,
  cidFileExcel,
  cidUserFemalePlus,  
  cidSchool,
  cidShieldCheck,
  cidAsteriskCircle,
  cisDollarCircle,  
  cisUpdate,
  cidCloudDownload,
  cisScanner,
  cisCloudUpload,
  cidHeadphonesMic,
  cidGrid,
  cisColumns,
  cisPage,
  cidTransfer,
  cisArrowRight,
  cisCalendarViewDay,
  cidStorage,
  cidShareBoxed,
  cidBullhorn,
  cidHourglass,
  cisFlagAlt,
  })
